import { FeedbackToast, InformativeToast, NotificationToast, ToastManager } from "@beckersdp/becker-ds";
import React, { FC, useEffect } from "react";
import { ToastTypesEnum } from "../../constants/toast-ids";
import { useToasts } from "../../hooks";
import { useAppDispatch } from "../../hooks/store";
import { removeToastItem } from "../../store/toasts";

export const Toasts: FC = () => {
  const { toasts } = useToasts();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (toasts.length > 0) {
      toasts.map(toast => {
        const { toastType, isToShowAfterRedirect, ...rest } = toast;
        if (!isToShowAfterRedirect) {
          if (toastType === ToastTypesEnum.FEEDBACK) {
            FeedbackToast.show(rest);
          } else if (toastType === ToastTypesEnum.INFORMATIVE) {
            InformativeToast.show(rest);
          } else {
            NotificationToast.show(rest);
          }
          dispatch(removeToastItem(rest.id));
        }
      });
    }
  }, [toasts]);

  return <ToastManager />;
};
