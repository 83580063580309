export enum LocalStorageType {
  "SELECTED_HOME_WIDGET_LICENSE" = "selected_home_widget_license",
  "POPUPS_SESSION" = "popups-session",
  "TENANT_REF_KEY" = "tenant_ref_key",
  "SHOW_OLD_TRACKER_TOASTER" = "show_old_tracker_toaster",
  "GET_CREDITS_FILTERS" = "get_credits_filters",
  "COMPLIANCE_USERS_FILTERS" = "compliance_users_filters",
  "HIDE_NY_BANNER_SWITCH_VIEW_MODE" = "hide_ny_banner_switch_view_mode",
  "HIDE_NY_BANNER_SWITCH_SUBJECT_AREA" = "hide_ny_banner_switch_subject_area"
}

export enum SessionStorageType {
  "HOME_COMPLIANCE_OLD_TRACKER_POPOVER" = "home_compliance_old_tracker_popover",
  "CRM_MODAL_SKIP" = "crm_modal_skip"
}
