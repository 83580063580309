import { CourseDetailsResponseModel } from "@cpe/services/courses";
import { createSlice } from "@reduxjs/toolkit";
import { CourseDetails } from "./thunks";

export interface CoursesDetailsState {
  details: CourseDetailsResponseModel | undefined;
  loading: boolean;
  error: string | null;
}
export const initialState: CoursesDetailsState = {
  details: undefined,
  loading: false,
  error: null
};

export const courseDetailsSlice = createSlice({
  name: "courses",
  initialState: initialState,
  reducers: {
    registerCourseDetails: state => {
      if (state.details) {
        state.details.actions = state.details?.actions.filter(action => action !== "REGISTER");
        state.details.actions.push("UNREGISTER");
        state.details.state.registered = true;
      }
    },
    unregisterCourseDetails: state => {
      if (state.details) {
        state.details.actions = state.details?.actions.filter(action => action !== "UNREGISTER");
        state.details.actions.push("REGISTER");
        state.details.state.registered = false;
      }
    },
    joinWebcastCourseDetails: state => {
      if (state.details) {
        state.details.status = "IN_PROGRESS";
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(CourseDetails.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.error = response?.message || "Something is wrong.";
        state.details = undefined;
      } else {
        state.details = action.payload;
        state.error = "";
      }
      state.loading = false;
    });
    builder.addCase(CourseDetails.rejected, state => {
      state.loading = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(CourseDetails.pending, state => {
      state.error = "";
      state.loading = true;
    });
  }
});

export const { registerCourseDetails, unregisterCourseDetails, joinWebcastCourseDetails } = courseDetailsSlice.actions;
export default courseDetailsSlice.reducer;
