import { Course } from "@cpe/models/course";
import React, { createContext, Dispatch, FC, SetStateAction, useContext } from "react";
import { GaPlayerEntranceProps, UA_GaPlayerEntranceProps } from "../../ga/events/player-entrance";
import { useCourseCard } from "./use-course-card";

interface Props {
  children: React.ReactNode;
  course: Course;
  onToggleSave: (course: Course) => Promise<void>;
  onCardExitAnimation?: () => void;
  gaOptions?: GaPlayerEntranceProps;
  UA_gaOptions?: UA_GaPlayerEntranceProps;
}

interface ContextType {
  register: (event: React.SyntheticEvent) => Promise<void>;
  unregister?: (event: React.SyntheticEvent) => Promise<void>;
  buy: (event: React.SyntheticEvent) => Promise<void>;
  launch: (event: React.SyntheticEvent) => Promise<void>;
  addToCart: (event: React.SyntheticEvent) => Promise<void>;
  creditsAlreadyEarned: boolean;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  addingToCart: boolean;
  setAddingToCart: Dispatch<SetStateAction<boolean>>;
  setCreditsAlreadyEarned: Dispatch<SetStateAction<boolean>>;
  hideCreditsAlreadyEarnDialog: () => boolean;
  toggleSave: (course: Course) => Promise<void>;
  course: Course;
  onCardExitAnimation?: () => void;
}

const CourseCardContext = createContext<ContextType | undefined>(undefined);

export const CourseCardProvider: FC<Props> = props => {
  const { course, onToggleSave, onCardExitAnimation, gaOptions, UA_gaOptions, ...rest } = props;
  const courseCardActions = useCourseCard(course, gaOptions, UA_gaOptions);

  return (
    <CourseCardContext.Provider
      value={{ ...courseCardActions, toggleSave: onToggleSave, course, onCardExitAnimation }}
      {...rest}
    />
  );
};

/**
 * Uses the context provided by the `CourseCardContext`.
 *
 * @returns {CourseCardContextValue} - The provided context values.
 */
export const useCourseCardContext = (): ContextType => {
  const context = useContext(CourseCardContext);

  if (context === undefined) {
    throw new Error("useCourseCardContext must be used within a CourseCardContextProvider");
  }

  return context;
};

export default CourseCardContext;
