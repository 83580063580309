import { Dropdown, getScreenCheckers } from "@beckersdp/becker-ds";
import { NavigationLink, permissions } from "@cpe/models/navigation-link";
import React, { FC, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { SessionStorageType } from "../../../../../../constants/storage";
import { GA_COMPLIANCE_NAVIGATION } from "../../../../../../ga/events/compliance/compliance-breakdown";
import { sendGaEvent } from "../../../../../../ga/ga";
import { useNavigation, useStorage, useUser } from "../../../../../../hooks";
import { PageQALabels } from "../../../../../../page-objects/components/page/qa-labels";
import { availableRoutes } from "../../../../../../router/routes";
import { useAppDispatch } from "../../../../../../store";
import { Cart } from "../../../../../../store/cart/thunks";
import useComplianceDisclaimerContext from "../../../../../compliance-legal-disclaimer/compliance-disclaimer-provider";
import { useFeatureFlags } from "../../../../../feature-flag/use-feature-flags";
import { ImpersonateBanner } from "../../../../../impersonate-banner";
import { useHeaderUrls } from "../../use-header-urls";
import { CompliancePopover } from "../compliance-popover/compliance-popover";
import { NavOption } from "../desktop-header/styles";
import MiniCartToggle from "../mini-cart-toggle";
import { HamburgerIcon } from "./hamburguer-icon";
// eslint-disable-next-line
const BeckerLogo = require("../../../../../../assets/logo.svg");

export const MobileHeader: FC = () => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null); // Popper's ref for mini cart
  const theme = useTheme();
  const { isPhone } = getScreenCheckers(theme.breakpoints, window.innerWidth);
  const { navigation } = useNavigation();
  const { help, account } = useHeaderUrls();
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  const { sessionStorage } = useStorage();
  const dispatch = useAppDispatch();
  const { isFFActive } = useFeatureFlags();
  const { shouldShowComplianceDisclaimer, openComplianceDisclaimer } = useComplianceDisclaimerContext();

  const showCompliancePopover = sessionStorage[SessionStorageType.HOME_COMPLIANCE_OLD_TRACKER_POPOVER];

  useEffect(() => {
    user.shoppingCart && dispatch(Cart());
  }, [user.shoppingCart]);

  const navOptions = navigation.map((link: NavigationLink, index: number) => (
    <CustomOption
      key={`mobile-header-link-${index}`}
      onClick={() => {
        setOpen(!open);
        const isComplianceLink =
          link.permission === permissions.complianceTracker || link.url === availableRoutes.compliance;
        const isMyComplianceLink = link.url === availableRoutes.compliance;

        if (link.external) {
          window.open(link.url, "_blank");
        } else {
          if (isMyComplianceLink && shouldShowComplianceDisclaimer()) {
            openComplianceDisclaimer();
            return;
          }
          isComplianceLink && sendGaEvent(GA_COMPLIANCE_NAVIGATION);
          window.location.href = link.url;
          // history.push(link.url); //TODO: uncomment when student migration is finished
        }
      }}
    >
      {link.description}
    </CustomOption>
  ));
  const helpOptions = help.map((link, index) => (
    <CustomOption
      key={index}
      data-qa-label={link.permission.toLowerCase().replace(" ", "")}
      onClick={() => {
        if (link?.external) {
          window.open(link.url, "_blank");
        } else {
          window.location.href = link.url;
        }
      }}
    >
      {link.description}
    </CustomOption>
  ));

  const accountOptions = account.map((link, index) => (
    <CustomOption key={`account-${index}`} onClick={link.action}>
      {link.name}
    </CustomOption>
  ));

  const menuOptions =
    helpOptions?.length > 0
      ? [...navOptions, <Divider key="div-1" />, ...helpOptions, <Divider key="div-2" />, ...accountOptions]
      : [...navOptions, ...accountOptions];

  const renderMenu = () => (
    <>
      {user.shoppingCart && (
        <MiniCartToggle isMobile setReferenceElement={setReferenceElement} referenceElement={referenceElement} />
      )}
      <MenuContainer onBlur={() => setOpen(!open)}>
        <Dropdown
          placement="bottom-end"
          menu={<CustomDropdownMenu>{menuOptions.map(option => option)}</CustomDropdownMenu>}
        >
          <NavOption>
            <HamburgerIcon open={open} />
          </NavOption>
        </Dropdown>
      </MenuContainer>
    </>
  );

  return (
    <Header data-qa-label={PageQALabels.header} $sticky={isFFActive("UI_FOR_IMPERSONATING_ACCOUNT")}>
      <ImpersonateBanner />
      <Nav isPhone={isPhone}>
        <StyledLogo />
        {showCompliancePopover ? (
          <Container>
            <StyledCompliancePopover>
              <Content>{renderMenu()}</Content>
            </StyledCompliancePopover>
          </Container>
        ) : (
          <Container>{renderMenu()}</Container>
        )}
      </Nav>
    </Header>
  );
};

const Divider = styled.div`
  border-top: 1px solid #122048;
  width: 100%;
  padding-top: 5px;
  margin-top: 5px;
`;

const Header = styled.header<{ $sticky: boolean }>`
  box-sizing: border-box;
  position: ${props => (props.$sticky ? "sticky" : "fixed")};
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-color: ${props => props.theme.colors?.white};
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
  z-index: 1000;
`;

const Nav = styled.div<{ isPhone?: boolean }>`
  box-sizing: content-box;
  height: 40px;
  margin: 0 ${props => (props.isPhone ? "10px" : "25px")};
  display: flex;
  align-items: center;
`;
const StyledLogo = styled(BeckerLogo)`
  max-height: 100%;
  max-width: 100px;
  height: 30px;
  flex-shrink: 0;
`;

const Container = styled.div`
  height: 100%;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const MenuContainer = styled.div`
  display: flex;
`;

const CustomDropdownMenu = styled(Dropdown.Menu)`
  box-shadow: none;
  right: 0;
  top: 10px !important;
  width: 100vw;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background-color: #f1f1f4;
  padding-top: 5px;
`;

const CustomOption = styled(Dropdown.Option)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.colors.brand01[700]};
  background-color: transparent;

  &:hover {
    text-decoration: underline;
    background-color: transparent;
  }

  padding: 5px 10px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledCompliancePopover = styled(CompliancePopover)`
  height: 100%;
`;
