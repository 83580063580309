import { defaultTheme, getScreenCheckers, ScreenContextValue } from "@beckersdp/becker-ds";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";
import { isMobileSafari } from "react-device-detect";

const IOS_DELAY_READ_WINDOW_DIMENSIONS = 550;

const getOrientation = (): "landscape" | "portrait" => {
  if (window.orientation) {
    return window.orientation === -90 || window.orientation === 90 ? "landscape" : "portrait";
  }
  return window.innerWidth > window.innerHeight ? "landscape" : "portrait";
};

export const useScreen = (): ScreenContextValue => {
  const [screenSize, setScreenSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });
  const [orientation, setOrientation] = useState(getOrientation());

  const screenCheckers = getScreenCheckers(defaultTheme.breakpoints, screenSize.width);
  const handleOrientationChange = useCallback(() => setOrientation(getOrientation()), []);

  useEffect(() => {
    window.addEventListener("orientationchange", handleOrientationChange);
    return () => window.removeEventListener("orientationchange", handleOrientationChange);
  }, []);

  useEffect(() => {
    const handleScreenResize = () => {
      const height = window.innerHeight;
      const width = window.innerWidth;
      setScreenSize({ width, height });
      handleOrientationChange();
    };

    const debouncedHandler = debounce(
      () =>
        // Mobile Safari BUG https://hackernoon.com/onresize-event-broken-in-mobile-safari-d8469027bf4d
        isMobileSafari ? setTimeout(handleScreenResize, IOS_DELAY_READ_WINDOW_DIMENSIONS) : handleScreenResize(),
      50
    );

    window.addEventListener("resize", debouncedHandler);

    return () => window.removeEventListener("resize", debouncedHandler);
  }, []);

  return {
    ...screenSize,
    ...screenCheckers,
    orientation
  };
};
