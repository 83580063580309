import { Course, CourseActions, CourseSchedule } from "@cpe/models/course";
import moment from "moment";

export const hasAction = (course: Course, action: CourseActions) =>
  course?.actions?.filter(courseAction => courseAction === action).length > 0;
export const isOnDemand = (course: Course) => ["COURSE", "NANOCOURSE", "FLASHCOURSE"].indexOf(course.format) > -1;
export const isWebcast = (course: Course) => ["WEBCAST", "LIVE", "REBROADCAST"].indexOf(course.format) > -1;
export const isLearningPath = (course: Course) => ["LEARNINGPATH"].indexOf(course.format) > -1;
export const isCertificateProgram = (course: Course) => ["CERTIFICATE_PROGRAM"].indexOf(course.format) > -1;

export const isConference = (course: Course) => course.format === "CONFERENCE";

export const shouldRenderCompletedTag = (course: Course) =>
  !isConference(course) && (course.status === "COMPLETED" || course.completion);

export const shouldRenderRating = (course: Course) => {
  if (!course.rating?.total || !course.rating?.score) {
    return false;
  }
  if (isWebcast(course)) {
    return true;
  }
  if (
    isConference(course) ||
    ["IN_PROGRESS", "COMPLETED"].includes(course.status) ||
    (isOnDemand(course) && hasAnotherCompletion(course))
  ) {
    return false;
  }
  return true;
};

export const hasAnotherCompletion = (course: Course): boolean => {
  return (course.completion && course.completion.productId !== course.productId) || false;
};
export const hasItsOwnCompletion = (course: Course): boolean => {
  return (course.completion && course.completion.productId === course.productId) || false;
};

export const getDateFormated = (schedule: CourseSchedule, timezone: string) => {
  const startDate = moment(schedule.startDate).tz(timezone);
  const endDate = moment(schedule.endDate).tz(timezone);
  const isSameYear = startDate.year === endDate.year;
  const isSameMonth = startDate.month === endDate.month;
  if (isSameYear && isSameMonth) {
    return `${startDate.format("MMM DD")} - ${endDate.format("DD, YYYY")}`;
  }
  if (isSameYear && !isSameMonth) {
    return `${startDate.format("MMM DD")} - ${endDate.format("MMM DD, YYYY")}`;
  }
  if (!isSameYear && !isSameMonth) {
    return `${startDate.format("MMM DD, YYYY")} - ${endDate.format("MMM DD, YYYY")}`;
  }
  return;
};
